
import { computed, defineComponent, PropType } from 'vue';
import { CouponDataI } from '../interfaces/digital-coupon.interface';
import { formatDate } from '@/utils/general-utils';

export default defineComponent({
  name: 'PaymentDetail',
  props: {
    data: {
      type: Object as PropType<CouponDataI>,
      required: true,
    },
  },
  setup(props) {
    const financial = (value: string) => Number.parseFloat(value).toFixed(2);

    const isLince = computed(() => props.data.fondeador_id === '0');

    const isIris = computed<boolean>(
      () => String(props.data.fondeador_id) === '1'
    );

    const getUrlSucursal = () => process.env.VUE_APP_API_URL;

    const inDefault = (obligation: CouponDataI) => {
      const message = 'PAGO INMEDIATO';

      const anchorId = Number(obligation.fondeador_id);

      if (anchorId === 0 && Number(obligation.cuotas_en_mora) > 0) {
        return message;
      }

      if (anchorId > 0 && obligation.pague_hasta === '1800-01-01') {
        return message;
      }

      if (
        anchorId === 2 &&
        formatDate(new Date()) >= formatDate(obligation.pague_hasta)
      ) {
        return message;
      }

      return formatDate(obligation.pague_hasta);
    };

    return {
      isIris,
      isLince,
      financial,
      inDefault,
      getUrlSucursal,
    };
  },
});
